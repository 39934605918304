export const
  ROOT = '/',
  /* FILE DOWNLOAD APP ROUTES*/
  PUBLIC_IDENTIFIER = '/:publicIdentifier',
  /* MEET LINKS APP ROUTES */
  MEETING_PATH = '/:meetingPath',
  PRESENTER_PATH = '/presenter',
  PARTICIPANT_PATH = '/participant',
  /* PASSPORT APP ROUTES */
  NOT_FOUND_PATH = '/404',
  WELCOME_PATH = '/welcome',
  AGENDA_PATH = '/agenda',
  SESSION_PATH = '/session',
  REPLAYS_PATH = '/replays',


  SESSION_MEETING_PATH = '/session/:eventId/meeting/:meetingId',
  AGENDA_MEETING_PATH = '/agenda/:eventId/meeting/:meetingId',
  CONNECTION_KNOVIO_PATH = '/stream/:meetingId',
  SHOWCASE_PATH = '/showcase',
  SUPPORT_PATH = '/support',
  REGISTRATION_PATH = '/registration',
  GOOGLE_EVENT = '/google-event',
  SPEAKERS_PATH = '/speakers',
  TRIAGE_ANSWER_REGISTRATION_PATH = '/registration/:triageAnswer',
  CALENDAR_SERVICE_PATH = '/calendar-service'
;

export enum DefaultPathLabel {
  SIGN_IN = 'Sign In',
  REGISTRATION = 'Registration',
  NOT_FOUND_PATH_LABEL = '404',
  CONNECTION_KNOVIO_PATH_LABEL = 'Stream'
}

export const PASSPORT_PATH_LIST = [
  NOT_FOUND_PATH,
  SHOWCASE_PATH,
  CONNECTION_KNOVIO_PATH,
  AGENDA_PATH, AGENDA_MEETING_PATH,
  SESSION_PATH, SESSION_MEETING_PATH,
  WELCOME_PATH,
  SUPPORT_PATH,
  REGISTRATION_PATH,
  SPEAKERS_PATH,
  REPLAYS_PATH
];
