import React, { useRef, RefObject, useEffect } from 'react';
import { PrivateMeetingSessionDetailsResponseType } from '../../../../lib/api';
import { TimingBlock } from './TimingBlock';
import { FooterButtons } from './FooterButtons';
import { EventType } from '../LayoutWrapper';
import { truncate } from '../helpers';
import './Card.scss';
import { IAuthContext, useAuth } from '../../../../lib/context-providers/auth-context';
import { MeetingPresentersList } from '../MeetingPresenterList';
import { MeetingTopic } from '../MeetingTopic';
import { useHistory } from 'react-router-dom';
import { SESSION_PATH } from '../../../../config/routes/paths';

export interface ICardProps {
  index?: number;
  event: EventType;
  meeting: PrivateMeetingSessionDetailsResponseType;
  featured?: boolean;
  eventToken: string;
}

const limit = 76;

const CardComponent = (props: ICardProps): JSX.Element => {
  const auth = useAuth() as IAuthContext;
  const isAuthenticated = !!auth.isAuthenticated;

  const topicRef: RefObject<HTMLDivElement> | null = useRef<HTMLDivElement>(null);
  const {
    meeting,
    event,
    featured = meeting.featured,
    eventToken,
  } = props;
  const { startDateTime, endDateTime, topic,
    thumbnailImageUrl, notes, presenterOrganizations } = meeting;
  const history = useHistory();
  const className = 'card-wrapper';
  const classNameFeatured = 'card-wrapper-featured sm:flex-row flex-col-reverse';
  useEffect(() => {
    if (topicRef.current && !featured) {
      const text = truncate(topicRef.current.textContent ?? '', limit);
      topicRef.current.textContent = text;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`item ${className} ${featured ? classNameFeatured : 'flex-col-reverse'}`} data-meeting-id={meeting.meetingId}>
      <div
        style={{
          height: !featured ? '75%' : ''
        }}
        className={`flex flex-col justify-between ${featured ? 'md:p-4 p-8 sm:w-full' : 'w-full hover:bg-gray-100'}`}
      >
        <div className={`${featured ? '' : 'p-3 flex flex-col-reverse'}`}>
          <MeetingTopic
            orgs={presenterOrganizations ?? []}
            topic={topic}
            isFeatured={featured}
            topicRef={topicRef}
          />
          {featured && <MeetingPresentersList orgs={meeting.presenterOrganizations ?? []} />}
          <TimingBlock
            meetingDetails={{
              startDateTime,
              endDateTime
            }}
            featured={featured}
            hideStartDate={true}
            hideEndDateTime={true}
            timeZone={event.timeZone}
            isMultiMeetingsBlock={false}
          />
        </div>
        {featured && notes
          ? <div className='flex flex-col mb-4 pr-4 word-break overflow-hidden' dangerouslySetInnerHTML={{ __html: notes }} />
          : <div style={{ height: '50px' }} />
        }
        {isAuthenticated &&
          <FooterButtons
            event={event}
            meeting={meeting}
            isCarousel
            isSessionsTab={history.location.pathname === SESSION_PATH}
            withTooltip={!featured}
            featured={featured}
            eventToken={eventToken}
          />
        }
      </div>
      <div
        style={{
          height: featured ? '100%' : '25%'
        }}
        className={`bg-gray-100 ${featured ? 'sm:w-1/3 w-full' : ' w-full'}`}
      >
        {thumbnailImageUrl
          ? (
            <img
              width='100%'
              src={thumbnailImageUrl}
              alt='thumbnailImageUrl'
              title='thumbnailImageUrl'
              style={{ objectFit: 'cover' }}
              className={`h-full ${featured ? 'max-h-64 ' : ''}`}
            />
          )
          : null
        }
      </div>
    </div>
  );
};

export { CardComponent };