import { AuthenticationMethod } from "../config/api";
import { getEventNameFromUrl } from "../lib/helpers/urlHelper";

class GlobalStore {
  private _authType: AuthenticationMethod | null = AuthenticationMethod.COGNITO;
  private _eventName: string | null = '';
  private static instance: GlobalStore | null = null;

  constructor() {
    this._eventName = getEventNameFromUrl();
  }

  public static getInstance(): GlobalStore {
    if (this.instance === null) {
      this.instance = new GlobalStore();
    }
    return this.instance;
  }

  get authenticationMethod(): AuthenticationMethod | null {
    return this._authType;
  }

  set authenticationMethod(value: AuthenticationMethod) {
    this._authType = value;
  }

  get eventName(): string | null {
    return this._eventName;
  }

  set eventName(value: string) {
    this._eventName = value;
  }

  get isPassportAuthFlow(): boolean {
    return this._authType === AuthenticationMethod.OE_PASSPORT;
  } 
}

export default GlobalStore;