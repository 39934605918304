import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { BACKGROUND_IMG_URL } from '../../lib/constants';
import DefaultSpinner from '../shared/DefaultSpinner';
import { useEventPublicInfo } from '../../hooks/api/public/use-event-public-details';
import EventDetails from '../shared/EventDetails';
import { sendCalendarInvite } from '../../lib/api/public';

export const CalendarServicePage: React.FC = () => {
  const { data } = useEventPublicInfo();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const attendeeId = queryParams.get('attendeeId');
  const eventTitle = data?.eventTitleForPassport || data?.name;

  const [toastMessage, setToastMessage] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const sendInvite = async (attendeeId: string) => {
    if (!attendeeId || !data?.eventId) return;

    try {
      await sendCalendarInvite({ attendeeId: String(attendeeId), eventId: String(data?.eventId) });
      setToastMessage(true);
    } catch (err) {
      setError('Something went wrong. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!attendeeId) {
      setError('Something went wrong. Please try again.');
      setLoading(false);
      return;
    }

    sendInvite(attendeeId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attendeeId]);

  return (
    <div className="flex flex-col items-center justify-center h-64 mt-16 p-2">
      {data && (
        <div className="w-full">
          <EventDetails details={data} className="w-full" />
        </div>
      )}

      <div className="mt-20 w-full">
        {loading
          ? <DefaultSpinner visible={loading} />
          : toastMessage
            ? <div className="toast-message">
              <div>
                We have emailed you a calendar invite for <strong>{eventTitle}</strong>.
                <br />
                Any changes to the schedule will stay up to date on your calendar.
              </div>
            </div>
            : error
              ? <div className="mt-5 text-red-500 font-medium text-center">{error}</div>
              : null}
      </div>
      <img className="background-img" src={BACKGROUND_IMG_URL} alt="Background" />
    </div>
  );
};
