import React, { useRef } from 'react';
import { IGroupedMeetingsList } from '../GridLayout/index';
import { EventType } from '../LayoutWrapper';
import { CarouselWrapperByTrack } from './CarouselWrapper';
import './Card.scss';

export interface ICarouselSection {
  event: EventType;
  selectedDate: string;
  showAllDates: boolean;
  showPastMeetings: boolean;
  meetings: IGroupedMeetingsList;
  searchedValue: string;
  setFilteredMeetingsCount: (length: number) => void;
  eventToken: string;
}
const CarouselViewByTrack = (props: ICarouselSection): JSX.Element => {
  const { event, meetings, selectedDate, showPastMeetings, showAllDates, searchedValue, setFilteredMeetingsCount, eventToken } = props;
  const list = showAllDates ? Object.values(meetings).flatMap(m => m) : meetings[selectedDate];
  const filteredMeetingsCount = useRef(0);
  const handleSetFilteredMeetingsCount = (count: number): void => {
    filteredMeetingsCount.current = filteredMeetingsCount.current + count;
    setFilteredMeetingsCount(filteredMeetingsCount.current);
  };

  return (
    <CarouselWrapperByTrack
      event={event}
      meetings={list}
      key={selectedDate}
      selectedDate={selectedDate}
      showAllDates={showAllDates}
      showPastMeetings={showPastMeetings}
      searchedValue={searchedValue}
      setFilteredMeetingsCount={count => handleSetFilteredMeetingsCount(count)}
      eventToken={eventToken}
    />
  );
};

export { CarouselViewByTrack };