import { useReducer, useState } from 'react';
import Observable from 'zen-observable';
import { registerCompleteSubscription } from '../config/appsync';
import { OnRegisterCompletedSubscription } from '../config/appsync/API';
import { Hub } from 'aws-amplify';
import { AttendeeUrlProcessingPolling } from '../lib/services/polling/attendee-url-processing-polling';
import { AttendeeProcessingResultType } from '../lib/api';

type SubscriptionData = { value: { data: OnRegisterCompletedSubscription }};

export type SubscriptionError = { reason: string, message: string };

export type RegistrationSubscriptionState = {
  inProgress: boolean,
  magicLink: null | string,
  error: null | SubscriptionError
}

export enum ACTION_TYPE {
  INITIALIZED = 'INITIALIZED',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  CANCELLED = 'CANCELLED'
}

type ACTION =
  | { type: ACTION_TYPE.INITIALIZED }
  | { type: ACTION_TYPE.COMPLETED; payload: string }
  | { type: ACTION_TYPE.FAILED, payload?: string }
  | { type: ACTION_TYPE.CANCELLED }
;

type RegistrationResponse = { registrationId: string, appSyncToken: string };

export type CancelSubscription = () => void;

export type SubscriptionResult = {
  state: RegistrationSubscriptionState,
  subscribe(response: RegistrationResponse | void): Promise<void | CancelSubscription>
}

const getErrorByKey = (key?: string): SubscriptionError => {
  switch (key) {
  case 'NOT_AN_ATTENDEE':
    return {
      reason: key,
      message: `Your email address is not currently on the list of registered attendees for this event.`
    };
  case 'Approved':
    return {
      reason: key,
      message: `Thank you for registering. Please check your email for details of how to attend the event.`
    };
  default:
    return {
      reason: key || '',
      message: key ? key : `Your system settings do not allow us to sign you in automatically. We have sent you an email which contains details of how to sign in.`
    };
  }
};

const _defaults: RegistrationSubscriptionState = { inProgress: false, error: null, magicLink: null };

export function reducer(state: RegistrationSubscriptionState, action: ACTION): RegistrationSubscriptionState {
  switch (action.type) {
  case ACTION_TYPE.INITIALIZED:
    return { ..._defaults, inProgress: true };
  case ACTION_TYPE.COMPLETED:
    return { ..._defaults, magicLink: action.payload };
  case ACTION_TYPE.FAILED:
    return { ..._defaults, error: getErrorByKey(action.payload) };
  case ACTION_TYPE.CANCELLED:
    return _defaults;
  default:
    return _defaults;
  }
}


let timeoutId: number | null = null;

const ONE_MINUTE_TIMEOUT = 60*1000;
const initialState = { inProgress: false, magicLink: null, error: null };

export const useRegistrationSubscription = (): SubscriptionResult => {
  const [ state, dispatch ] = useReducer(reducer, initialState);
  const [ cancelled, cancel ] = useState<boolean>(false);
  const cancelSubscription = () => cancel(true);

  const initSubscription = (client: {unsubscribe(): void, closed: boolean}) => {
    timeoutId = window.setTimeout(() => {
      if (!client.closed) client.unsubscribe();
      subscriptionFailed();
      cancelSubscription();
    }, ONE_MINUTE_TIMEOUT);

    dispatch({ type: ACTION_TYPE.INITIALIZED });
  };
  const subscriptionCompleted = (payload: string) => dispatch({ type: ACTION_TYPE.COMPLETED, payload });
  const subscriptionFailed = (payload?: string) => dispatch({ type: ACTION_TYPE.FAILED, payload });
  const subscriptionCancelled = () => dispatch({ type: ACTION_TYPE.CANCELLED });

  return {
    state,
    async subscribe(response: RegistrationResponse): Promise<void | CancelSubscription> {
      return new Promise((resolve, reject) => {
        const params = [response?.registrationId, response?.appSyncToken] as [string, string];

        if (!params.every(Boolean)) return resolve();

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore*/
        const client = (registerCompleteSubscription(...params) as Observable<SubscriptionData>)
          .subscribe({
            next({ value }: SubscriptionData): void {
              const url = value?.data?.onRegisterCompleted?.magicLink ?? '';
              const error = value?.data?.onRegisterCompleted?.error;

              if (!cancelled && !error) {
                window.location.replace(url);
                subscriptionCompleted(url);
              }

              if (!cancelled && error) {
                subscriptionFailed(error);
                if (timeoutId) window.clearTimeout(timeoutId);
              }

              client.unsubscribe();
            },
            error(): void {
              subscriptionFailed();
              client.unsubscribe();
            },
            complete(): void {
              if (timeoutId) window.clearTimeout(timeoutId);
              if (!client.closed) client.unsubscribe();
            }
          })
        ;

        initSubscription(client);

        const manuallyCancelSubscription: CancelSubscription = () => {
          if (!client.closed) client.unsubscribe();
          if (timeoutId) window.clearTimeout(timeoutId);
          subscriptionCancelled();
        };

        const cancelSubscriptionOnConnectionFailure = (): void => {
          if (!client.closed) client.unsubscribe();
          if (timeoutId) window.clearTimeout(timeoutId);
          subscriptionFailed();
          cancelSubscription();
        };

        /* Resolve when the subscription connection is established or reject if client fails to connect */
        Hub.listen('api', ({ payload }) => {
          const { event, data } = payload;

          if (event === 'Subscription ack') resolve(manuallyCancelSubscription);

          if (data.connectionState === 'ConnectionDisrupted') {

            /* stop subscription client */
            if (!client.closed) client.unsubscribe();
            if (timeoutId) window.clearTimeout(timeoutId);

            const polling = new AttendeeUrlProcessingPolling(response?.registrationId, response?.appSyncToken);

            /* start api polling */
            polling.start()
              .then((response) => {
                if (response.type === AttendeeProcessingResultType.NOT_AN_ATTENDEE) {
                  subscriptionFailed(AttendeeProcessingResultType.NOT_AN_ATTENDEE);
                } else {
                  if (response.registrationResult) {
                    window.location.replace(response.registrationResult);
                    subscriptionCompleted(response.registrationResult);
                  }
                }
              })
              .catch(() => {
                cancelSubscriptionOnConnectionFailure();
                reject(new Error('ConnectionDisrupted'));
              });

            /* resolving manual cancellation fn when polling subscription connection established */
            polling.subscribeToPollingStart(() => {
              resolve(() => {
                polling.stop();
                subscriptionCancelled();
              });
            });
          }
        });
      });
    }
  };
};
