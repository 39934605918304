import React from 'react';
import { MeetingAttendeeDetailsType, MeetingOrganizationDetailsListType } from '../../lib/api';

interface IProps {
  blockName: string;
  organizations: MeetingOrganizationDetailsListType;
  attendeeClick?: (attendeeId: number) => void;
  isMeetingLink?: boolean;
}

const OrganizationsList: React.FC<IProps> = (props: IProps) => {
  if (!props.organizations.length) return null;
  const { blockName, organizations, isMeetingLink, attendeeClick } = props;

  const handleAttendeeClick = (att: MeetingAttendeeDetailsType) => {
    if (attendeeClick && (att.profileImageUrl || att.biography)) {
      attendeeClick(att.attendeeId);
    }
  };

  return (<div className='pt-2'>
    {blockName && <h3 className='text-primary font-size-12px px-0 sm:pl-8 sm:pr-12'>{blockName}</h3>}
    {organizations.map((org, key) => {
      return (
        <div className={`${isMeetingLink ? 'px-0 sm:pr-12 mb-6' : 'px-0 sm:pl-8 sm:pr-12' }`} key={key}>
          <h3 className={`font-medium font-size-18px ${isMeetingLink ? '' : 'pb-1'}`}>{org.name}</h3>
          {!!org.attendees && org.attendees.map((att) =>
            <p
              onClick={() => handleAttendeeClick(att)}
              className={`font-size-14px ${attendeeClick && (att.profileImageUrl || att.biography) ? 'hover:underline cursor-pointer' : ''}`}
              key={att.attendeeId}
            >
              {isMeetingLink ?
                <>
                  <span className='font-medium text-black'>{att.firstName} {att.lastName}{/* {att.title && ', '} */}</span>
                  {att.title && <span>, {att.title}</span>}
                </>
                :
                <>
                  <span className='font-semibold'>{att.firstName} {att.lastName}</span>{(att.firstName || att.lastName) && att.title && `, `}{att.title}
                </>
              }
            </p>
          )}
        </div>
      );
    })}
  </div>);
};

export default OrganizationsList;
