import React from 'react';
import {
  AttendanceMethodOptionLabels,
  ErrorProps,
  FieldsProps,
  TouchedProps,
  ValidatorResultType,
  ValidatorType,
  ValuesProps
} from '../passport/RegistrationWrapper/RegistrationForm/field-settings';
import Select from '../../components/_base/Select';
import ErrorBlock from '../../components/_base/ErrorBlock';
import { CustomFieldType } from '../passport/RegistrationWrapper/RegistrationForm/RegistrationForm';
import Checkbox from '../../components/_base/Checkbox';
import { CheckBoxMapper } from '../../lib/constants';

export interface SelectProps {
  validators: ValidatorType[];
  field: FieldsProps,
  values: ValuesProps,
  touched: TouchedProps;
  errors: ErrorProps;
  optional?: boolean;
  handleChange: (
    key: string,
    value: string,
    validators: ValidatorType[]) => void;
  handleBlur: (
    key: string,
    value: string,
    validators: ValidatorType[]) => void;
  onChangeCallback?: (key: string, value: string) => void;
}

const SelectBox = (props: SelectProps): JSX.Element => {
  const {
    field,
    values,
    handleChange,
    validators,
    errors,
    handleBlur,
    optional,
    onChangeCallback,
  } = props;

  const error: boolean | ValidatorResultType | undefined = errors[field.name]?.find(err => !err.valid);
  const isError: boolean = !errors[field.name]?.map(({ valid }) => valid).every(Boolean) || (!optional && !values[field.name]);

  return (
    <div className='mt-3 word-break'>
      {field.fieldType === CustomFieldType.dropdown ?
        <Select
          id={field.name}
          label={field.label}
          onChange={(value: string) => {
            handleChange(field.name, value, validators);
            if (onChangeCallback) {
              onChangeCallback(field.name, value);
            }
          }}
          value={values[field.name]}
          span={field.optional ? '(optional)' : undefined}
          options={field.optionsList || []}
          optionLabels={field.name === 'attendanceMethod' ? AttendanceMethodOptionLabels : undefined}
          error={isError}
          onBlur={(): void => {
            handleBlur(field.name, values[field.name], validators);
          }}
        />
        :
        <Checkbox
          label={field.label}
          onChange={(e: React.FormEvent<HTMLInputElement>): void => {
            handleChange(field.name, `${e.currentTarget.checked}`.toUpperCase(), validators);
          }}
          onBlur={(): void => {
            handleBlur(field.name, values[field.name], validators);
          }}
          value={CheckBoxMapper[values[field.name]]}
          error={isError}
        />
      }
      <div className="negative-margin">
        {error?.validationMessage && <ErrorBlock errorMessage={error.validationMessage} classname={'py-40px'} />}
      </div>
    </div>
  );
};

export default SelectBox;