import { useMemo } from 'react';
import { PrivateMeetingSessionDetailsResponseType } from '../../../../lib/api';
import { useComposeMeeting } from '../useComposeMeeting';
import { useFilteredCarouselSessions } from '../../../../hooks/use-search-sessions';

interface Carousel {
  featuredMeetings: Array<PrivateMeetingSessionDetailsResponseType>;
  noGroupMeetings: Array<PrivateMeetingSessionDetailsResponseType>;
  trackMeetings: Array<PrivateMeetingSessionDetailsResponseType>;
  noFeaturedMeetings: Array<PrivateMeetingSessionDetailsResponseType>;
}

export interface CarouselArg {
  meetings:PrivateMeetingSessionDetailsResponseType[];
  selectedDate: string;
  showPastMeetings: boolean;
  groupByDay?: boolean;
  searchedValue: string;
}
const withTrack = (track: string) => (Boolean(track));
const withFeaturedWithoutTrack = ({ featured, track }: PrivateMeetingSessionDetailsResponseType) => !withTrack(track) && featured;

const response = {
  featuredMeetings: [],
  noGroupMeetings: [],
  trackMeetings: [],
  noFeaturedMeetings: [],
};

const getGroupByTrack = (activeMeetings: PrivateMeetingSessionDetailsResponseType[]) => {
  const featuredMeetings: Array<PrivateMeetingSessionDetailsResponseType> = [];
  const trackMeetings: Array<PrivateMeetingSessionDetailsResponseType> = [];
  const noGroupMeetings: Array<PrivateMeetingSessionDetailsResponseType> = [];
  activeMeetings.forEach(meeting => {
    if (withFeaturedWithoutTrack(meeting)) {
      return featuredMeetings.push(meeting);
    } else if (withTrack(meeting.track)) {
      return trackMeetings.push(meeting);
    } else {
      return noGroupMeetings.push(meeting);
    }
  });

  return ({
    ...response,
    featuredMeetings,
    trackMeetings,
    noGroupMeetings
  });
};

const getGroupByDay = (activeMeetings: PrivateMeetingSessionDetailsResponseType[]) => {
  const featuredMeetings: Array<PrivateMeetingSessionDetailsResponseType> = [];
  const noFeaturedMeetings: Array<PrivateMeetingSessionDetailsResponseType> = [];
  activeMeetings.forEach(meeting => {
    if (meeting.featured) {
      return featuredMeetings.push(meeting);
    } else {
      return noFeaturedMeetings.push(meeting);
    }
  });

  return ({
    ...response,
    featuredMeetings,
    noFeaturedMeetings
  });
};

const useCarousel = ({ meetings, selectedDate, showPastMeetings, searchedValue, groupByDay = false }: CarouselArg): Carousel => {
  const { searchedMeetings } = useFilteredCarouselSessions(meetings, searchedValue);
  const { activeMeetings } = useComposeMeeting(searchedMeetings, selectedDate, showPastMeetings);

  const groupByCondition = useMemo((): Carousel => {
    if (groupByDay) {
      return getGroupByDay(activeMeetings);
    } else {
      return getGroupByTrack(activeMeetings);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMeetings]);

  return ({ ...groupByCondition });
};

export { useCarousel };