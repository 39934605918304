import React, { createContext, useContext, useEffect, useState } from 'react';
import { SWRConfig } from 'swr';
import { AuthenticationMethod, HTTP_STATUS } from '../../config/api';
import { AuthError, UseAuthError, useAuthError } from './use-auth-error';
import { useEventPublicInfo } from '../../hooks/api/public/use-event-public-details';
import { authenticate, getAccessToken, invalidate, removeTokens, validateCurrentAuthenticatedUser } from '../auth';
import { AppContextType, useApp } from './app-config-context';
import GlobalStore from '../../app/global-store';

export type IAuthContext = {
  isAuthenticated: boolean | null,
  authError: AuthError,
  resetAuthError(): void,
  signIn(email: string, accessCode: string): Promise<boolean>,
  signOut(): Promise<void>,
  forceSignOut(): Promise<void>,
  passwordlessAuthInProgress: boolean,
  invalidLink: boolean,
  resetInvalidLink(): void,
  setInvalidLink(): void,
  setAsAuthenticated(): void,
  setAsNotAuthenticated(): void,
  setPasswordlessAuthInProgress(): void,
  resetPasswordlessAuthInProgress(): void,
  ssoError: AuthError,
  setSsoError(val: AuthError): void,
  isLoading: boolean;
}

const useAuthProvider = () => {
  const [isAuthenticated, setAuthStatus] = useState<boolean | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [passwordlessAuthInProgress, setupPasswordlessAuthInProgress] = useState<boolean>(true);
  const [invalidLink, setupInvalidLink] = useState<boolean>(false);
  const [ssoError, setupSsoError] = useState<AuthError>(null);

  const setAsAuthenticated = (): void => setAuthStatus(true);
  const setAsNotAuthenticated = (): void => setAuthStatus(false);

  const { error: authError, setError } = useAuthError() as UseAuthError;
  const resetAuthError = () => setError(null);
  const resetInvalidLink = () => setupInvalidLink(false);
  const setInvalidLink = () => setupInvalidLink(true);
  const setPasswordlessAuthInProgress = () => setupPasswordlessAuthInProgress(true);
  const resetPasswordlessAuthInProgress = () => setupPasswordlessAuthInProgress(false);
  const setSsoError = (error: AuthError) => setupSsoError(error);
  const globalStore = GlobalStore.getInstance();

  const { data } = useEventPublicInfo();
  globalStore.authenticationMethod = data?.authenticationMethod as AuthenticationMethod;

  const { isConfigured } = useApp() as AppContextType;

  useEffect(() => {
    setIsLoading(true);

    if (isConfigured && data?.authenticationMethod) {
      validateCurrentAuthenticatedUser()
        .then(setAsAuthenticated)
        .catch(() => {
          removeTokens();
          setAsNotAuthenticated();
        })
        .finally(() => {
          resetPasswordlessAuthInProgress();
          setIsLoading(false);
        });
    }

    if (isConfigured === false) {
      setAsNotAuthenticated();
    }

  }, [isConfigured, data?.authenticationMethod]);

  const signIn = async (email: string, accessCode: string): Promise<boolean> => {
    setIsLoading(true);

    try {
      const username = `${email.trim()}_${data?.eventId}`;
      await authenticate(username, accessCode);
      setAsAuthenticated();
      return true;
    } catch (e) {
      setAsNotAuthenticated();
      setError(e as AuthError);
      resetPasswordlessAuthInProgress();
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  const resetData = () => {
    resetInvalidLink();
    resetPasswordlessAuthInProgress();
    removeTokens();
    window.history.replaceState(null, '', '/');
  };

  const signOut = async (): Promise<void> => {
    try {
      await invalidate();
    } finally {
      setAsNotAuthenticated();
      resetData();
      window.location.replace('/');
    }
  };

  const forceSignOut = async (): Promise<void> => {
    setAsNotAuthenticated();
    resetData();
  };

  return {
    isAuthenticated, authError, resetAuthError,
    signIn, signOut, forceSignOut,
    passwordlessAuthInProgress, invalidLink,
    resetInvalidLink,
    setAsAuthenticated,
    setAsNotAuthenticated,
    setInvalidLink,
    setPasswordlessAuthInProgress,
    resetPasswordlessAuthInProgress,
    ssoError,
    setSsoError,
    isLoading
  };
};
const authContext = createContext<IAuthContext | void>(undefined);
type Props = {
  children: React.ReactNode | React.ReactNodeArray
}
export const AuthProvider: React.FC<Props> = ({ children }: Props): JSX.Element => {
  const auth = useAuthProvider() as IAuthContext;
  const onError = async (error: AuthError): Promise<void> => {
    const shouldSignOut = GlobalStore.getInstance().isPassportAuthFlow
      ? error?.status === HTTP_STATUS.UNAUTHORIZED && !getAccessToken()
      : error?.status === HTTP_STATUS.UNAUTHORIZED;

    if (shouldSignOut) await auth.forceSignOut();
    if (error?.ssoError) auth.setSsoError(error);
  };
  return (
    <authContext.Provider value={auth}>
      <SWRConfig value={{ onError }}>
        {children}
      </SWRConfig>
    </authContext.Provider>
  );
};
export const useAuth = (): IAuthContext | void => useContext(authContext);
export default AuthProvider;
