import React, { useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import RegistrationForm from './RegistrationForm/RegistrationForm';
import EventPublicInfo from '../LoginWrapper/EventPublicInfo/EventPublicInfo';
import LoginForm from '../LoginWrapper/LoginForm/LoginForm';
import { REGISTRATION_PATH, ROOT } from '../../../config/routes/paths';
import { EventPublicDetailsResponseTypeWithCustomField } from '../../../hooks/api/public/use-event-public-custom-fields';
import { useCustomFieldsByTriageAnswerSettings, useCustomFieldsSettings } from '../../../hooks/use-custom-fields';
import RegisterBlock, { FormFooterBlockType } from '../LoginWrapper/RegisterBlock/RegisterBlock';
import Buttons from '../../../components/button';
import useEventCalendar from '../../../hooks/use-event-calendar';
import { useRegistrationSubscription } from '../../../hooks/use-registration-subscription';
import DefaultSpinner from '../../shared/DefaultSpinner';
import { useEventGeneralSessionList } from '../../../hooks/api/public/use-event-general-session-list';
import EventDetails from '../../shared/EventDetails';
import './RegistrationWrapper.scss';

type Props = {
  signIn(email: string, code: string): Promise<void>;
  data: EventPublicDetailsResponseTypeWithCustomField;
  location: { from?: string, errorMsg?: string; email?: string };
  selectedTriageAnswer: string;
};

const getEventTitle = (customTitle: string | undefined, defaultTitle: string): string => {
  return customTitle ? customTitle : defaultTitle;
};

const RegistrationWrapper: React.FC<Props> = (props: Props): JSX.Element => {
  const {
    signIn,
    data,
    location,
    selectedTriageAnswer,
  } = props;

  const history = useHistory();

  const { state, subscribe } = useRegistrationSubscription();
  const subscriptionInProgress = !!(state.inProgress || (state.magicLink && !state.error));
  const subscriptionError = (!state.inProgress && !state.magicLink && state.error) || null;

  const {
    customFields,
    loading,
    triagedRegistrationEnabled,
    configuration: defaultConfiguration
  } = useCustomFieldsSettings(data.eventId, data.registrationEnabled, selectedTriageAnswer);

  const {
    customFields: triageAnswerCustomFields,
    loading: fetching = false,
    error: triageAnswerCustomFieldsError,
    triagedRegistrationEnabled: triagedEnabled,
    configuration: triageConfiguration
  } = useCustomFieldsByTriageAnswerSettings(data.eventId, selectedTriageAnswer, data.registrationEnabled);

  const registrationConfiguration = selectedTriageAnswer ? triageConfiguration : defaultConfiguration;
  const isProRegistrationView = registrationConfiguration?.showOpenMeetings;
  const formFields = selectedTriageAnswer ? triageAnswerCustomFields : customFields;
  const isTriageQuestionDisabled = triagedRegistrationEnabled === false || triagedEnabled === false;

  const { data: generalSessionList } = useEventGeneralSessionList(data.eventId.toString(), registrationConfiguration?.showOpenMeetings);
  const { fetchOutlookCalendarForEvent, fetchGoogleCalendarForEvent } = useEventCalendar();
  const [showErrorRes, setErrorRes] = useState<boolean>(false);
  const [showRegistrationForm, setRegistrationForm] = useState<boolean>(true);
  const [showSubmitMessage, setShowSubmitMessage] = useState<boolean>(false);
  const [emailText, setEmailText] = useState<string>('');
  const [eventToken, setToken] = useState<string | undefined>(undefined);
  const [showApprovalWaitingMessage, setShowApprovalWaitingMessage] = useState<boolean>(false);

  const submitMessage = `Thank you for registering.  Please check your email for details of how to attend the event.`;
  const errorMessage = `Sorry, registration parameters were changed. Please refresh the page.`;
  const approvalWaitingMessage = 'Please wait while we confirm your registration';
  const clName = `shadow-gray mt-6 md:mt-0 min-w-full md:min-w-0 ${isProRegistrationView ? 'bg-white w-full px-10' : 'w-342px'}`;
  const clNameFormWrap = formFields?.length ?
    `registration-wrapper login-wrapper p-6 ${isProRegistrationView ? 'bg-white registration-wrapper--pro' : 'bg-white-opacity-95'} ` :
    'login-wrapper p-6 bg-white ';
  const fileName = `${getEventTitle(data.eventTitleForPassport, data.name)}.ics`;
  const showSubscriptionError = subscriptionError && !showErrorRes;

  const setEventToken = (token: string) => {
    setToken(token);
  };

  const handleRedirect = (url: string) => {
    history.push(`/registration/?formName=${encodeURIComponent(url)}`);
  };

  if (triageAnswerCustomFieldsError || (selectedTriageAnswer && isTriageQuestionDisabled)) {
    return <Redirect to={REGISTRATION_PATH} />;
  }
  return (
    <div className={`flex justify-between flex-col ${isProRegistrationView ? 'registration-container flex-row' : 'md:flex-row'}`}>
      { isProRegistrationView ? <EventDetails details={data}/> : <EventPublicInfo data={data}/> }
      <div className={clName}>
        { isProRegistrationView && <h1 className='registration-container__header h1-font-size leading-10 break-words'>
          { showSubmitMessage ? 'Sign In' : (registrationConfiguration?.registrationHeader || 'Register') }
        </h1> }
        <div
          className={clNameFormWrap}
        >
          {!showSubscriptionError && showSubmitMessage && !showApprovalWaitingMessage && (registrationConfiguration?.registrationSubmittedMessage ?
            <>
              <span
                className={`break-words ${isProRegistrationView ? 'block my-4' : ''}`}
                dangerouslySetInnerHTML={{ __html: registrationConfiguration?.registrationSubmittedMessage ?? '' }}
              >
              </span>
            </> :
            <>
              <span>{submitMessage}</span>
              <hr className='mt-4 mb-4'/>
            </>)
          }
          {
            showSubscriptionError &&
              <>
                <span>{subscriptionError?.message}</span>
                <hr className='mt-4 mb-4'/>
              </>
          }
          {
            showErrorRes && !showSubmitMessage && !subscriptionInProgress &&
              <>
                <span>{errorMessage}</span>
                <hr className='mt-4 mb-4'/>
              </>
          }
          {
            showSubmitMessage && registrationConfiguration?.calendarButtonEnabled && !subscriptionInProgress
              ? (
                <div className='flex w-full'>
                  <Buttons.OptionsButton
                    label={'+ Add to Calendar'}
                    name={'addToCalendar'}
                    disabled={false}
                    eventId={data.eventId.toString() || ''}
                    eventName={data.name || ''}
                    className='select-box--button-small'
                    downloadIcsFile={()=> fetchOutlookCalendarForEvent(data.eventId.toString() || '', fileName, eventToken)}
                    openGoogleCalendar={()=> fetchGoogleCalendarForEvent(data.eventId.toString() || '',false, eventToken)}
                  />
                  <hr className='mt-4 mb-4'/>
                </div>
              )
              : null
          }
          {
            showApprovalWaitingMessage && subscriptionInProgress &&
              <>
                <span>{approvalWaitingMessage}</span>
                <hr className='mt-4 mb-4'/>
              </>
          }
          <DefaultSpinner visible={subscriptionInProgress} />
          {loading && showRegistrationForm && !showErrorRes && !subscriptionInProgress &&
            <RegistrationForm
              key={Math.random()}
              autoSignInEnabled={registrationConfiguration?.autoSignInEnabled}
              eventId={data.eventId}
              registrationHeader={registrationConfiguration?.registrationHeader}
              registrationInstructions={registrationConfiguration?.registrationInstructions}
              customFields={formFields || []
              }
              setErrorRes={setErrorRes}
              showSubmitMessage={setShowSubmitMessage}
              toggleRegistrationForm={() => setRegistrationForm(!showRegistrationForm)}
              setEmailText={setEmailText}
              registrationSubscription={subscribe}
              setEventToken={setEventToken}
              location={location}
              isProRegistrationView={isProRegistrationView}
              generalSessionList={generalSessionList || []}
              eventTimeZone={data.timeZone}
              publicDescription={data.publicDescription}
              handleRedirect={handleRedirect}
              selectedTriageAnswer={selectedTriageAnswer}
              shouldRenderRegisterButton={!!selectedTriageAnswer || isTriageQuestionDisabled}
              showSpinner={fetching}
              registrationApprovalEnabled={data.registrationApprovalEnabled}
              showApprovalWaitingMessage={setShowApprovalWaitingMessage}
            />
          }
          {showSubmitMessage && !subscriptionInProgress &&
            <LoginForm
              signIn={signIn}
              errorMsg={data.loginErrorMessage}
              hideSubmitMessage={() => history.push(ROOT)}
              showSubmitMessage={showSubmitMessage}
              emailText={emailText}
              privacyPolicyEnabled={data.privacyPolicyEnabled}
              privacyPolicyLabel={data.privacyPolicyLabel}
              isProRegistrationView={isProRegistrationView}
            />}
        </div>
        {!subscriptionInProgress && <RegisterBlock type={FormFooterBlockType.signIn} isProRegistrationView={isProRegistrationView} />}
      </div>
    </div>
  );
};

export default RegistrationWrapper;
