import React, { useState, useRef, RefObject, useEffect } from 'react';
import { utcToZonedTime } from 'date-fns-tz';
import { PrivateMeetingSessionDetailsListResponseType } from '../../../../lib/api';
import MeetingTimingBlock from '../../../shared/MeetingTimingBlock';
import { ARRAY_INCREMENT_SIZE } from '../../../../lib/constants';
import SessionsPagination, { IPagination } from './SessionsPagination';
import SessionMeetingsTile from './SessionMeetingsTile';
import { EventType } from '../LayoutWrapper';
import { getActiveTracks } from '../helpers';
import { IMeetingsByStartTime } from '../LayoutWrapper';
import { useComposeMeeting } from '../useComposeMeeting';
import './GroupedList.scss';
import { MediaQueryContextType, useMediaQuery } from '../../../../lib/context-providers/media-query-provider';
import { useFilteredCarouselSessions } from '../../../../hooks/use-search-sessions';

export type PrivateMeetingSessionList = PrivateMeetingSessionDetailsListResponseType;

export interface IGroupedMeetingsList {
  [date: string]: PrivateMeetingSessionList;
}

export interface IProps {
  event: EventType;
  selectedDate: string;
  isExpandedView: boolean;
  showAllDates: boolean;
  showPastMeetings: boolean;
  meetings: IGroupedMeetingsList;
  activeMeetings: PrivateMeetingSessionList;
  meetingsByStartTime: IMeetingsByStartTime[];
  eventToken: string;
  searchedValue: string;
  setFilteredMeetingsCount: (meetingsNumber: number) => void;
}

export interface IGroupedMeetingsListProps {
  event: EventType;
  isExpandedView: boolean;
  showAllDates: boolean;
  activeMeetings: PrivateMeetingSessionList;
  meetingsByStartTime: IMeetingsByStartTime[];
  getTileWidth: () => void;
  pagination: IPagination;
  showPastMeetings: boolean;
  eventToken: string;
}

const GroupedMeetingsList = ({
  meetingsByStartTime,
  showPastMeetings,
  event,
  isExpandedView,
  activeMeetings,
  pagination,
  getTileWidth,
  eventToken,
}: IGroupedMeetingsListProps): JSX.Element => {
  const nowDateInMs = new Date().getTime();
  const className = 'editable-session';
  const classNameSessionTableRow = `${className}--table-row`;
  const classNameSessionTableRowLeft = `${className}--table-row-left`;
  const classNameSessionTableRowRight = `${className}--table-row-right`;
  const classNameSessionTableRowRightTile = `${className}--table-row-right-tile`;

  const mediaQuery = useMediaQuery() as MediaQueryContextType;
  const isMobile = mediaQuery?.isMobile;

  const getSessionTableRowRightTileClassNames = () => {
    let classnames = classNameSessionTableRowRightTile + ' mx-2 mt-2 flex flex-col';

    if (!isMobile) {
      classnames += ' max-w-40vw w-' + getTileWidth() + 'px';
    } else {
      classnames += ' w-full';
    }

    return classnames;
  };

  return (
    <div role='presentation'>
      {meetingsByStartTime
        .filter(ms => showPastMeetings || ms.endDateTime.getTime() > nowDateInMs)
        .map((ms, i) => {
          const currentTimeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
          const attendeeEndDateTime: Date = utcToZonedTime(ms.endDateTime, currentTimeZone);
          return (
            <div
              role='rowgroup'
              key={i}
              className={`${attendeeEndDateTime < new Date() ?
                classNameSessionTableRow + ' past-meetings' :
                classNameSessionTableRow}`
              }
            >
              <div role='row' className='flex p-2 bg-primary-lighter-hover min-h-168px'>
                <div role='rowheader' className={`${classNameSessionTableRowLeft} pr-5 pl-3 pt-5 timing-column`}>
                  <MeetingTimingBlock
                    meetingDetails={{
                      startDateTime: ms.startTime,
                      endDateTime: ms.endDateTime
                    }}
                    timeZone={event.timeZone}
                    hideStartDate={true}
                    hideEndDateTime={true}
                    isMultiMeetingsBlock={meetingsByStartTime[i].meetingsList.length > ARRAY_INCREMENT_SIZE}
                  />
                </div>
                <div className={`${classNameSessionTableRowRight} w-full flex`}>
                  {activeMeetings
                    .filter(m => m.startDateTime === ms.startTime && m.featured)
                    .map((m, k) => (
                      <SessionMeetingsTile
                        key={k}
                        meeting={m}
                        event={event}
                        isExpandedView={isExpandedView}
                        eventToken={eventToken}
                      />
                    ))}
                  <div className='flex'>
                    {getActiveTracks(activeMeetings).map((t, k) => {
                      if (k < pagination.firstIndex || k > pagination.lastIndex) return null;
                      return (
                        <div
                          className={getSessionTableRowRightTileClassNames()}
                          role='gridcell'
                          key={k}
                        >
                          {activeMeetings
                            .filter(m => m.startDateTime === ms.startTime && m.track === t && !m.featured)
                            .map((m, k) => (
                              <SessionMeetingsTile
                                key={k}
                                meeting={m}
                                event={event}
                                isExpandedView={isExpandedView}
                                eventToken={eventToken}
                              />
                            ))}
                        </div>
                      );
                    })}
                  </div>
                </div>
                {!isMobile && <div className='w-50px'>{/* place for meetings pagination controllers */}</div>}
              </div>
              <hr/>
            </div>
          );
        })}
    </div>
  );
};

type IGridLayoutComponent = Omit<IProps, 'activeDates' | ''>

export const GridLayoutComponent: React.FC<IGridLayoutComponent> = (props: IGridLayoutComponent): JSX.Element => {
  const {
    event,
    showAllDates,
    selectedDate,
    isExpandedView,
    showPastMeetings,
    meetingsByStartTime,
    activeMeetings,
    eventToken,
  } = props;

  const { isMobile } = useMediaQuery() as MediaQueryContextType;

  const [pagination, setPaginationConfig] = useState<IPagination>({
    firstIndex: 0,
    lastIndex: 3,
    step: 0
  });

  const pageContent: RefObject<HTMLDivElement> | null = useRef<HTMLDivElement>(null);

  const className = 'editable-session';
  const classNameSessionTableHeader = `${className}--table-header`;
  const classNameSessionTableHeaderLeft = `${className}--table-header-left`;
  const classNameSessionTableHeaderRight = `${className}--table-header-right`;

  const getTileWidth = (): number => {
    const expandedWidth = 300;
    const collapsedWidth = 140;
    return isExpandedView ? expandedWidth : collapsedWidth;
  };
  const getTracksClassName = (): string => {
    return 'w-' + getTileWidth() + 'px font-size-15px font-semibold pl-2 mx-2 truncate max-w-40vw';
  };

  useEffect(() => {
    props.setFilteredMeetingsCount(activeMeetings.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMeetings.length]);

  return (
    <>
      <div role='grid' aria-label='General Sessions'>
        <div role='rowgroup'>
          <div role='row' className={`${classNameSessionTableHeader} bg-gray-11 border-b flex items-center`} ref={pageContent}>
            <div role='columnheader' className={`${classNameSessionTableHeaderLeft} p-2 pl-5 timing-column bg-white `}>
              {/* TODO: check if this data is correct */}
              <span style={{ color: '#053984' }} className="font-size-12px font-bold uppercase">{selectedDate}</span>
            </div>
            <div className={`${classNameSessionTableHeaderRight} w-full flex flex-row py-2`}>
              {getActiveTracks(activeMeetings)
                .slice(pagination.firstIndex, pagination.lastIndex + ARRAY_INCREMENT_SIZE)
                .map((t, k) => (
                  <span role='columnheader' className={`${getTracksClassName()} ${isMobile ? 'pl-5' : ''}`} key={k}>
                    {t}
                  </span>
                ))}
            </div>
            <SessionsPagination
              itemWidth={getTileWidth()}
              trackLength={getActiveTracks(activeMeetings).length}
              setPaginationConfig={setPaginationConfig}
              {...pagination}
              pageContentBlock={pageContent.current || null}
            />
          </div>
        </div>
        <GroupedMeetingsList
          event={event}
          pagination={pagination}
          showAllDates={showAllDates}
          getTileWidth={getTileWidth}
          activeMeetings={activeMeetings}
          isExpandedView={isExpandedView}
          showPastMeetings={showPastMeetings}
          meetingsByStartTime={meetingsByStartTime}
          eventToken={eventToken}
        />
      </div>
    </>
  );
};

export const Wrapper = (props: IProps): JSX.Element | null => {
  const { meetings, selectedDate, showPastMeetings } = props;
  const { searchedMeetings } = useFilteredCarouselSessions(meetings[selectedDate], props.searchedValue);
  const { activeMeetings, meetingsByStartTime } = useComposeMeeting(searchedMeetings, selectedDate, showPastMeetings);
  const isActiveMeetingsExist = Boolean(activeMeetings.length);
  return isActiveMeetingsExist
    ? (
      <GridLayoutComponent
        {...props}
        activeMeetings={activeMeetings}
        meetingsByStartTime={meetingsByStartTime}
      />
    )
    : null;
};

const GridLayout:React.FC<IProps> = (props: IProps): JSX.Element => {
  const { showAllDates, meetings } = props;
  const filteredMeetingsCount = useRef(0);

  useEffect(() => {
    props.setFilteredMeetingsCount(filteredMeetingsCount.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredMeetingsCount.current]);

  if (showAllDates) {
    return (
      <>
        { Object.keys(meetings).map((date, i) => (
          <Wrapper 
            key={i} 
            {...props} 
            selectedDate={date}
            setFilteredMeetingsCount={meetingsNumber => filteredMeetingsCount.current = filteredMeetingsCount.current + meetingsNumber}
          />))}
      </>
    );
  }
  return (
    <GridLayoutComponent 
      {...props} 
      setFilteredMeetingsCount={meetingsNumber => filteredMeetingsCount.current = meetingsNumber}
    />
  );
};

export default GridLayout;
