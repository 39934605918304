import React from 'react';
import { Redirect } from 'react-router-dom';
import { EventSettingsContextType, useEventSettings } from '../../../lib/context-providers/event-settings-context/event-settings-context';
import EventDetails from '../../shared/EventDetails';
import SupportPageInfo from './SupportPageInfo';
import isEnabledRoute from '../../../hooks/use-enabled-route';

const SupportPageWrapper: React.FC = (): JSX.Element | null => {
  const data = useEventSettings() as EventSettingsContextType;

  if (data && !data?.supportEnabled) {
    const enabledRoute = isEnabledRoute(data?.landingPage);
    return <Redirect to={enabledRoute} />;
  }

  if (data?.supportInformation) return (
    <>
      <EventDetails details={data}/>
      <SupportPageInfo
        supportLabel={data?.supportLabel || 'Support'}
        supportInformation={data?.supportInformation}
      />
    </>
  );
  return null;
};

export default SupportPageWrapper;
