import React from 'react';
import { utcToZonedTime } from 'date-fns-tz';
import { EventPublicDetailsResponseType } from '../../lib/api';
import { getEventOffsetGMT, TimeZoneDisplayTypeLabels } from '../../lib/helpers/dateHelper';
import DateRange from './DateRange';
import useSetKeyIdClass from '../../hooks/use-set-key-id-class';
import { useTemplateProcessor } from '../../hooks/use-template-processor';

type PropsType = {
  className?: string,
  details: {
    endTime: string,
    startTime: string,
    name: string,
    timeZone: string,
    logoTitle?: string,
    logoUrl?: string,
    eventTitleForPassport?: string,
  } & Partial<EventPublicDetailsResponseType>
}

const EventDetails: React.FC<PropsType> = (props: PropsType) => {
  const { endTime, startTime, name, timeZone, logoTitle, logoUrl, eventTitleForPassport, timeZoneDisplayType, customTimeZoneDisplay = '' } = props.details;
  const startDateTime = utcToZonedTime(startTime, timeZone);
  const templateProcessor = useTemplateProcessor();
  const timeZoneLabel = getEventOffsetGMT(timeZoneDisplayType as TimeZoneDisplayTypeLabels, customTimeZoneDisplay, startDateTime, timeZone);
  const transformedTimeZone = timeZoneDisplayType === TimeZoneDisplayTypeLabels.Custom ? templateProcessor(timeZoneLabel) : timeZoneLabel;
  const wrapperCssClass = 'w-100 overflow-hidden flex flex-col md:flex-row justify-start md:justify-between mt-6 px-4 md:px-0 items-start';
  const headerCssClass = 'font-bold text-left md:text-right ';
  const classNameEventDetails = 'editable-' + useSetKeyIdClass() + '-event-details';
  const classNameEventDetailsName = `${classNameEventDetails}--name`;
  const classNameEventDetailsDate = `${classNameEventDetails}--date`;
  const classNameEventDetailsImage = `${classNameEventDetails}--image`;
  const classNameEventDetailsWrapper = `${classNameEventDetails}--wrapper`;
  const classNameEventDetailsTimeZone = `${classNameEventDetails}--time-zone`;

  return (
    <div className={`${classNameEventDetails} bg-white ml-2 ${props.className ? props.className : 'md:w-3/4'}}`}>
      <div className={wrapperCssClass} >
        {logoUrl ?
          <img
            src={logoUrl}
            alt={logoTitle}
            title={logoTitle}
            className={`${classNameEventDetailsImage} max-w-xs`}
            style={{ objectFit: 'contain', maxHeight: '8rem' }}
          /> :
          <div></div>}
        <div className={`${classNameEventDetailsWrapper} pt-2 ml-0 md:ml-6`} >
          <h1 className={`${classNameEventDetailsName} text-primary font-size-23px word-break pre-line ${headerCssClass}`}>
            {eventTitleForPassport || name}
          </h1>
          <div
            className={`${classNameEventDetailsDate} text-primary font-size-12px ${headerCssClass} uppercase flex justify-start md:justify-end`}
            style={{ letterSpacing: '0.6px' }}
          >
            <DateRange startTime={startTime} endTime={endTime} timeZone={timeZone}/>
          </div>
          <p className={`${classNameEventDetailsTimeZone} text-off-black font-size-11px text-left md:text-right`}>
            {transformedTimeZone}
          </p>
        </div>
      </div>
    </div>
  );
};

export default EventDetails;
