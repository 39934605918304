import GlobalStore from '../../app/global-store';
import { HTTP_STATUS, RequestHeaders, ResponseError, ResponseHeaders } from '../../config/api';
import { getAccessToken, getAccessTokenLifetime, invalidate, refreshPassportAuthToken } from '../auth';
import { SSO_ERROR_MSG } from '../constants';

/**
 * A flag indicating whether a refresh token operation is currently in progress.
 * Initialized to false, it is used to prevent multiple concurrent refresh token requests.
 */
let refreshTokenPromise = false;
const MAX_RETRIES = 3;

/* Check if response status is 204 or contentLength is 0 */
export const isResponseEmpty = (response: Response): boolean =>
  response?.status === HTTP_STATUS.NO_CONTENT ||
  response?.headers?.get(ResponseHeaders.ContentLength) === '0'
  ;

/**
 * Default fetch function used for API calls
 * @param url - API url to call
 * @param reqInit - Request config
 * @param retries - Retries count
 * @returns parsed Response or throw an Error
 */
export async function fetcher<T>(
  url: string | Request,
  reqInit: RequestInit = {},
  retries: number = MAX_RETRIES
): Promise<T> {
  await handleTokenRefresh(url as string, reqInit);
  const response = await fetch(url, reqInit);

  if (response.ok) {
    if (isResponseEmpty(response)) {
      return {} as T;
    } else {
      if (response.headers?.get(ResponseHeaders.ContentType) === 'application/octet-stream') {
        return response.blob() as unknown as Promise<T>;
      } else {
        return await response.json();
      }
    }
  }

  const {
    name = '',
    message = `Fetching the resource ${url} failed`,
    status = response.status,
    ssoError,
  } = await response.json();

  const error: ResponseError = { name, message };
  error.status = status;
  error.url = response.url;

  if (ssoError) {
    error.ssoError = ssoError;
    error.message = SSO_ERROR_MSG.USER_AUTH_FAILURE;
  }
  if (status === HTTP_STATUS.UNAUTHORIZED) {
    if (GlobalStore.getInstance().isPassportAuthFlow && retries > 0) {
      reqInit.headers = getAuthHeader(reqInit.headers as Headers);
      await fetcher(url, reqInit, retries - 1);
    } else {
      await invalidate();
    }
  }

  throw error;
}

/**
 * Handles the token refresh process for a given URL.
 *
 * @param url - The URL to evaluate for session refresh requirements.
 * @returns A promise that resolves when the token refresh process is complete.
 */
async function handleTokenRefresh(url: string, reqInit: RequestInit): Promise<void> {
  if (GlobalStore.getInstance().isPassportAuthFlow && shouldInvokeRefreshSession(url)) {
    if (!refreshTokenPromise) {
      refreshTokenPromise = true;
      await refreshPassportAuthToken();
      reqInit.headers = getAuthHeader(reqInit.headers as Headers);
      refreshTokenPromise = false;
    }
  }
}

const getAuthHeader = (headers: Headers): Headers => {
  const requestHeaders = new Headers({ ...headers });
  requestHeaders.set(RequestHeaders.AuthToken, getAccessToken() as string);

  return requestHeaders;
};

/**
 * Determines whether a session refresh should be invoked based on the URL and token lifetime.
 *
 * @param url - The URL to check for public or JWT paths.
 * @returns A boolean indicating if the session refresh should be triggered.
 */
const shouldInvokeRefreshSession = (url: string): boolean => {
  if (url.includes('/public') || url.includes('/jwt') || url.includes('/auth')) { return false; }

  const time = new Date().getTime();
  const tokenLifetime = getAccessTokenLifetime();
  const delta = 60000; // 1 minute in ms

  return (tokenLifetime - time) <= delta;
};
