import React, { useState, useEffect, useMemo } from 'react';
import { CarouselViewByTrack } from './CarouselLayout/CarouselViewByTrack';
import { SessionLayout } from '../../../lib/api/custom-page.types';
import { CarouselViewByDay } from './CarouselLayout/CarouselViewByDay';
import GridLayout, { IGroupedMeetingsList, PrivateMeetingSessionList } from './GridLayout/index';
import { EventPrivateDetailsResponseType, MeetingDetailsType, PrivateMeetingSessionDetailsResponseType } from '../../../lib/api';
import { useSessionMeetingsList } from '../../../hooks/api/protected/use-private-session-meeting-list';
import { EventPrivateSettingsType } from '../../../lib/context-providers/event-settings-context/event-settings-context';
import { ARRAY_INCREMENT_SIZE } from '../../../lib/constants';
import { getActiveTracks } from './helpers';
import { DateFilter } from './DateFilter';
import ToggleSessionsViewBtn from './GridLayout/ToggleSessionsViewBtn';
import TogglePastSessionsBtn from './GridLayout/TogglePastSessionsBtn';
import { useComposeMeeting } from './useComposeMeeting';
import { getGroupedMeetingsList } from '../../../lib/helpers/meetingHelper';
import { MediaQueryContextType, useMediaQuery } from '../../../lib/context-providers/media-query-provider';
import useEventToken from '../../../hooks/use-event-token';
import { SearchInput } from '../../../components/search-input/search-input';
import { useFilteredGridSessions } from '../../../hooks/use-search-sessions';
import './LayoutWrapper.scss';

export type EventType = EventPrivateDetailsResponseType & EventPrivateSettingsType;


export interface IMeetingsByStartTime {
  meetingsList: PrivateMeetingSessionList;
  startTime: string;
  endDateTime: Date;
}

interface IProps {
  event: EventType
}

export interface IHeaderProps {
  event: EventType;
  isGridLayout: boolean;
  isTogglePastMeetingsBtnShown: boolean;
  showPastMeetings: boolean;
  isExpandedView: boolean;
  setExpandedView: (isExpandedView: boolean) => void;
  changeShowPastFlag: (showPastMeetings: boolean) => void;
  showAllDates: boolean;
  activeDates: string[];
  selectedDate: string;
  changeShowAllDates: () => void;
  onSelectedDateChange: (date: string) => void;
  isMobile: boolean | void;
  handleSearch: (value: string) => void;
  filteredMeetingsLength: number;
  searchedValue: string;
}

export interface ILayoutComponentProps {
  event: EventType;
  isGridLayout: boolean;
  meetings: IGroupedMeetingsList;
  eventToken: string;
}

const HeaderComponent = (props: IHeaderProps): JSX.Element => {
  const {
    event,
    isGridLayout,
    isTogglePastMeetingsBtnShown,
    changeShowPastFlag,
    showPastMeetings,
    setExpandedView,
    isExpandedView,
    activeDates,
    showAllDates,
    selectedDate,
    changeShowAllDates,
    onSelectedDateChange,
    isMobile,
    handleSearch,
    filteredMeetingsLength,
    searchedValue,
  } = props;
  const className = 'editable-session';
  const classNameSessionHeader = `${className}--header`;
  const classNameSessionPast = `${className}--past-sessions`;
  const classNameSessionHeaderRight = `${className}--header-right`;

  return (
    <>
      <div className={`${classNameSessionHeader} pt-5 pl-4`}>
        <div className='w-full flex justify-between items-center md:flex-no-wrap flex-wrap'>
          <div className='flex justify-between md:w-9/12 w-full items-center'>
            <h2 className='pb-4 font-size-40px font-normal leading-none pt-1'>{event.sessionLabel}</h2>
            {isGridLayout && !isMobile
              ? (
                <ToggleSessionsViewBtn
                  isExpandedView={isExpandedView}
                  setExpandedView={setExpandedView}
                />
              )
              : null
            }
          </div>
          <SearchInput
            onChange={handleSearch}
            withDebounce
            withResetSearchButton
          />
        </div>
        {!!searchedValue && 
          <>
            <section className='sessions-search-result'>
              {filteredMeetingsLength ? filteredMeetingsLength : 'no'}&nbsp;
              search result{filteredMeetingsLength === 1 ? '' : 's'} for <pre>“{searchedValue}”</pre>
            </section>
            <hr />
          </>
        }
        {event.sessionNotes &&
          <div className={`${!isGridLayout ? 'pb-8' : ''} word-break`} dangerouslySetInnerHTML={{ __html: event.sessionNotes }} />
        }
        {/* TODO: delete after add filter to carousel layout */}
        {isGridLayout
          ? (
            <div className='flex items-center justify-between'>
              <DateFilter
                dates={activeDates}
                timeZone={event.timeZone}
                showAllDates={showAllDates}
                selectedDate={selectedDate}
                changeShowAllDates={changeShowAllDates}
                onSelectedDateChange={onSelectedDateChange}
                isTogglePastMeetingsBtnShown={isTogglePastMeetingsBtnShown}
              />
              {isTogglePastMeetingsBtnShown
                ? (
                  <div
                    style={{
                      width: '176px'
                    }}
                    className={classNameSessionHeaderRight + ' md:float-none whitespace-no-wrap'}
                  >
                    <TogglePastSessionsBtn
                      className={classNameSessionPast}
                      changeShowPastFlag={changeShowPastFlag}
                      showPastMeetings={showPastMeetings}
                    />
                  </div>
                )
                : null
              }
            </div>
          )
          : null
        }
      </div>
      <hr className={`${isGridLayout ? '' : 'mb-8'}`} />
    </>
  );
};

const SESSIONS_PAGE_STORAGE_KEY = 'sessionsPageParams';

const isMeetingFinished = (meeting: MeetingDetailsType): boolean => (new Date(meeting.endDateTime).getTime()) < (new Date()).getTime();
const isOngoingMeeting = (meeting: MeetingDetailsType): boolean => (new Date(meeting.endDateTime).getTime()) > (new Date()).getTime();

const LayoutComponent: React.FC<ILayoutComponentProps> = ({
  event,
  meetings,
  isGridLayout,
  eventToken
}: ILayoutComponentProps) => {
  const [showPastMeetings, setShowPastMeetings] = useState<Record<string, boolean>>({});
  const [isTogglePastMeetingsBtnShown, setIsTogglePastMeetingsBtnShown] = useState<boolean>(false);
  const [searchedValue, setSearchedValue] = useState('');
  const [filteredMeetingsLengthState, setfilteredMeetingsLengthState] = useState<number>(0);
  const handleChangeShowPastMeetings = (label: string, newState: boolean): void => {
    setShowPastMeetings(prev => ({
      ...prev,
      [label]: newState
    }));
  };

  const getDefaultSelectedDate = (): string => {
    const nowDateInMs = new Date().getTime();
    const days = Object.keys(meetings);
    let defaultSelectedDate = days[0];
    let selectedDateAlreadySet = false;
    days.forEach((k: string) => {
      const meetingsByDate = meetings[k];
      const endDateInMs = new Date(meetingsByDate[meetingsByDate.length - ARRAY_INCREMENT_SIZE].endDateTime).getTime();
      if (endDateInMs > nowDateInMs && !selectedDateAlreadySet) {
        defaultSelectedDate = k;
        selectedDateAlreadySet = true;
      }
    });
    return defaultSelectedDate;
  };

  const [selectedDate, setMeetingsDate] = useState<string>(() => getDefaultSelectedDate());
  const [showAllDates, changeShowAllDates] = useState<boolean>(!isGridLayout);

  const onSelectedDateChange = (selectedDate: string) => {
    window.sessionStorage.setItem(SESSIONS_PAGE_STORAGE_KEY, JSON.stringify({
      selectedDate,
      isExpandedView: isExpandedView,
      showAllDates: false
    }));
    setMeetingsDate(selectedDate);
    if (showAllDates) {
      changeShowAllDates(false);
    }
  };

  const activeDates = Object.keys(meetings);
  const date = selectedDate || getDefaultSelectedDate();
  const meetingsListByDate = meetings[date];

  const allMeetings = Object.values(meetings).reduce((_meetings, meetingsList) => _meetings.concat(meetingsList), []);
  const now = (new Date()).getTime();
  const allMeetingsInPast = allMeetings.every(meeting => (new Date(meeting.endDateTime)).getTime() < now);


  const checkIfAllMeetingsInFuture = (meetings: MeetingDetailsType[]): boolean => {
    let _allMeetingsInFuture = false;

    const meetingsGroupedByStartDateTime = meetings.reduce((group, meeting) => {
      group[meeting.startDateTime] = (group[meeting.startDateTime] || []).concat(meeting);
      return group;
    }, {} as Record<string, MeetingDetailsType[]>);

    for (const meetingGroup of Object.values(meetingsGroupedByStartDateTime)) {
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      if (meetingGroup.length === 1) {
        if (isMeetingFinished(meetingGroup[0])) {
          return false;
        } else {
          _allMeetingsInFuture = true;
        }
      } else {
        _allMeetingsInFuture = meetingGroup.some(isOngoingMeeting);
      }
    }

    return _allMeetingsInFuture;
  };

  const allMeetingsInFuture = checkIfAllMeetingsInFuture(allMeetings);
  const activeDateLabel = showAllDates ? showPastMeetings['showAllDates'] : showPastMeetings[selectedDate];
  const showPastMeetingsFlag = event.pastSessionsEnabled && !!activeDateLabel;
  const { searchedMeetings } = useFilteredGridSessions(meetingsListByDate, searchedValue);

  const { activeMeetings, meetingsByStartTime } = useComposeMeeting(searchedMeetings, date, showPastMeetingsFlag);
  const { meetingsByStartTime: initialMeetingsByStartTime } = useComposeMeeting(meetingsListByDate, date, showPastMeetingsFlag);

  const tracksLength = getActiveTracks(activeMeetings).length;
  const maxElementsToCollapsedView = 2;

  const mediaQuery = useMediaQuery() as MediaQueryContextType;
  const isMobile = mediaQuery?.isMobile;

  const defaultExpandedViewValue = () => {
    if (isMobile) return true;

    return tracksLength <= maxElementsToCollapsedView;
  };

  const [isExpandedView, setExpandedView] = useState<boolean>(defaultExpandedViewValue());

  const onExpandedViewChange = (isExpandedView: boolean) => {
    window.sessionStorage.setItem(SESSIONS_PAGE_STORAGE_KEY, JSON.stringify({
      selectedDate,
      isExpandedView: isExpandedView,
      showAllDates
    }));
    setExpandedView(isMobile ? true : isExpandedView);
  };

  const handleChangeShowAllDates = () => {
    const newToggleState = !showAllDates;
    changeShowAllDates(newToggleState);
    window.sessionStorage.setItem(SESSIONS_PAGE_STORAGE_KEY, JSON.stringify({
      selectedDate,
      isExpandedView: isExpandedView,
      showAllDates: newToggleState
    }));
  };


  const Layout = {
    [SessionLayout.GRID_VIEW_BY_TRACK]: () => (
      <GridLayout
        event={event}
        meetings={meetings}
        showAllDates={showAllDates}
        selectedDate={selectedDate}
        activeMeetings={activeMeetings}
        isExpandedView={isExpandedView}
        showPastMeetings={showPastMeetingsFlag}
        meetingsByStartTime={meetingsByStartTime}
        eventToken={eventToken}
        searchedValue={searchedValue}
        setFilteredMeetingsCount={length => setfilteredMeetingsLengthState(length)}
      />
    ),
    [SessionLayout.CAROUSEL_VIEW_BY_TRACK]: () => (
      <CarouselViewByTrack
        event={event}
        meetings={meetings}
        showAllDates={true}
        selectedDate={selectedDate}
        showPastMeetings={true}
        searchedValue={searchedValue}
        setFilteredMeetingsCount={length => setfilteredMeetingsLengthState(length)}
        eventToken={eventToken}
      />
    ),
    [SessionLayout.CAROUSEL_VIEW_BY_DAY]: () => (
      <CarouselViewByDay
        event={event}
        meetings={meetings}
        showAllDates={true}
        selectedDate={selectedDate}
        showPastMeetings={true}
        searchedValue={searchedValue}
        setFilteredMeetingsCount={length => setfilteredMeetingsLengthState(length)}
        eventToken={eventToken}
      />
    )
  }[event?.layout];

  useEffect(() => {
    const sessionsPageParams = window.sessionStorage.getItem(SESSIONS_PAGE_STORAGE_KEY);
    if (sessionsPageParams) {
      const { selectedDate, isExpandedView, showAllDates } = JSON.parse(sessionsPageParams);
      setExpandedView(isMobile ? true : isExpandedView);
      changeShowAllDates(showAllDates);
      if (meetings[selectedDate]) {
        setMeetingsDate(selectedDate);
      } else {
        setMeetingsDate(getDefaultSelectedDate());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetings]);

  useEffect(() => {
    const sessionsPageParams = window.sessionStorage.getItem(SESSIONS_PAGE_STORAGE_KEY);
    if (sessionsPageParams) {
      const { isExpandedView } = JSON.parse(sessionsPageParams);
      setExpandedView(isMobile ? true : isExpandedView);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  useEffect(() => {
    const nowDateInMs = new Date().getTime();
    const endedMeetings = initialMeetingsByStartTime.filter(m => m.endDateTime.getTime() > nowDateInMs);
    if (showAllDates) {
      if (!isGridLayout) {
        handleChangeShowPastMeetings('showAllDates', true);
        return;
      }
      if (allMeetingsInPast || allMeetingsInFuture) {
        handleChangeShowPastMeetings('showAllDates', true);
        return;
      }
      setIsTogglePastMeetingsBtnShown(event.pastSessionsEnabled);
      const showPastMeetingsState = showPastMeetings['showAllDates'] === undefined ? false : showPastMeetings['showAllDates'];
      handleChangeShowPastMeetings('showAllDates', showPastMeetingsState);
      
    } else {
      const isBtnShown = event.pastSessionsEnabled ? endedMeetings.length > 0 && endedMeetings.length !== initialMeetingsByStartTime.length : false;
      setIsTogglePastMeetingsBtnShown(isBtnShown);
      const showPastMeetingsState = showPastMeetings[selectedDate] === undefined ? !isBtnShown : showPastMeetings[selectedDate];
      handleChangeShowPastMeetings(selectedDate, showPastMeetingsState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allMeetingsInPast, meetingsByStartTime, showAllDates]);

  return (
    <>
      <HeaderComponent
        event={event}
        activeDates={activeDates}
        showAllDates={showAllDates}
        selectedDate={selectedDate}
        isGridLayout={isGridLayout}
        isExpandedView={isExpandedView}
        showPastMeetings={showPastMeetingsFlag}
        setExpandedView={onExpandedViewChange}
        changeShowPastFlag={newState => handleChangeShowPastMeetings(showAllDates ? 'showAllDates' : selectedDate, newState)}
        onSelectedDateChange={onSelectedDateChange}
        changeShowAllDates={() => handleChangeShowAllDates()}
        isTogglePastMeetingsBtnShown={isTogglePastMeetingsBtnShown}
        isMobile={isMobile}
        handleSearch={setSearchedValue}
        filteredMeetingsLength={filteredMeetingsLengthState}
        searchedValue={searchedValue}
      />
      <Layout />
    </>
  );
};

const LayoutWrapper: React.FC<IProps> = ({ event }: IProps) => {
  const isGridLayout = SessionLayout.GRID_VIEW_BY_TRACK === event?.layout;
  const isCarouselViewByDay = SessionLayout.CAROUSEL_VIEW_BY_DAY === event?.layout;

  const { data } = useSessionMeetingsList(String(event.eventId)) as { data?: PrivateMeetingSessionList };
  const eventToken = useEventToken(event?.eventId);
  const className = 'editable-session';
  const classNameSessionWrapper = `${className}--wrapper`;

  const meetings = useMemo(() => {
    if (!data) return;

    const groupedMeetings = getGroupedMeetingsList<PrivateMeetingSessionDetailsResponseType>(data, isCarouselViewByDay, event.timeZone);

    if (!event.pastSessionsEnabled) {
      Object.keys(groupedMeetings).forEach(dateKey => {
        const firstEl = groupedMeetings[dateKey][0];
        const lastEl = groupedMeetings[dateKey][groupedMeetings[dateKey].length - 1];
        const isFirstElInPast = new Date(firstEl.endDateTime).getTime() < Date.now();
        const isLastElInPast = new Date(lastEl.endDateTime).getTime() < Date.now();

        if (isFirstElInPast && isLastElInPast) {
          delete groupedMeetings[dateKey];
        }
      });
    }

    return groupedMeetings;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (!meetings) return null;

  const isDataExist = (meetings && !!Object.keys(meetings).length && true);

  return (
    <div className={`${classNameSessionWrapper} shadow-gray bg-white mx-auto md:w-3/4 md:px-8 py-8 mt-12 max-h-full h-70 w-100 mx-0 px-0`}>
      {
        isDataExist
          ? (
            <LayoutComponent
              event={event}
              isGridLayout={isGridLayout}
              meetings={meetings}
              eventToken={eventToken}
            />
          )
          : (
            <>
              <div className='font-size-40px pt-2 md:pb-8 pl-4 md:flex justify-between items-end leading-none'>
                {event.sessionLabel}
              </div>
              {event.sessionNotes &&
                <div className='py-4 sm:py-4 md:pb-8 md:pt-0 px-4 break-words' dangerouslySetInnerHTML={{ __html: event.sessionNotes }} />
              }
              <hr />
            </>
          )
      }
    </div>
  );
};

export { LayoutWrapper, LayoutComponent, HeaderComponent };
