import { useEffect, useMemo, useState } from 'react';
import { MeetingAttendeeDetailsListType, MeetingOrganizationDetailsListType } from '../lib/api';
import { PrivateMeetingSessionList } from '../app/passport/SessionWrapper/GridLayout';

type TOrganizationsOrAttendees = MeetingOrganizationDetailsListType | MeetingAttendeeDetailsListType;
type TMatchKeys = 'name' | 'fullname';
export type IUseSearchSessionsReturnType = {
  searchedMeetings: PrivateMeetingSessionList;
}

export function useFilteredCarouselSessions(meetings: PrivateMeetingSessionList, searchValue: string): IUseSearchSessionsReturnType {
  const searchedMeetings = useMemo(() => {
    return filteredMeetings(meetings, searchValue);
  }, [meetings, searchValue]);
  return { searchedMeetings };
}

export function useFilteredGridSessions(meetings: PrivateMeetingSessionList, searchValue: string): IUseSearchSessionsReturnType {
  const [searchedMeetings, setSearchedMeetings] = useState<PrivateMeetingSessionList>([]);
  
  useEffect(() => {
    setSearchedMeetings(filteredMeetings(meetings, searchValue));
  }, [searchValue, meetings]);

  return { searchedMeetings };
}

function filteredMeetings(
  meetingsList: PrivateMeetingSessionList,
  searchedValue: string
): PrivateMeetingSessionList {
  if (!searchedValue) return meetingsList;
  const filteredMeetingsResult: PrivateMeetingSessionList = [];
  const upperCasedValue = searchedValue.toUpperCase();
  for (const meeting of meetingsList) {
    const meetingTopic = meeting.topic || '';
    const isMatchTopic = meetingTopic.toUpperCase().indexOf(upperCasedValue) !== -1;
    if (isMatchTopic) {
      filteredMeetingsResult.push(meeting);
      continue;
    }
    const { presenterOrganizations } = meeting;
    const isMatch = filterByOrgNameOrFullname(presenterOrganizations as MeetingOrganizationDetailsListType, upperCasedValue, 0);
    if (isMatch) {
      filteredMeetingsResult.push(meeting);
    }
  }
  return filteredMeetingsResult;
}

const matchedKyes: TMatchKeys[] = ['name', 'fullname'];

function filterByOrgNameOrFullname<TItems extends TOrganizationsOrAttendees | MeetingAttendeeDetailsListType>(
  list: TItems,
  searchedValue: string,
  depth: number
): boolean {
  const matchedKey: TMatchKeys = matchedKyes[depth];
  switch (matchedKey) {
  case 'fullname':
    for (const attendee of list as MeetingAttendeeDetailsListType) {
      const fullName = attendee.firstName.toUpperCase().concat(' ', attendee.lastName.toUpperCase());
      if (fullName.indexOf(searchedValue) !== -1) {
        return true;
      }
    }
    break;

  case 'name':
    for (const presenterOrg of list as MeetingOrganizationDetailsListType) {
      const isMatchOrganizationName = presenterOrg.name.toUpperCase().indexOf(searchedValue) !== -1;
      if (isMatchOrganizationName) {
        return true;
      }

      const { attendees } = presenterOrg;
      const isMatch = filterByOrgNameOrFullname(attendees as MeetingAttendeeDetailsListType, searchedValue, depth + 1);
      if (isMatch) {
        return true;
      }
    }
    break;
  default:
    break;
  }

  return false;
}
