import React, { useRef } from 'react';
import { SessionLayout } from '../../../../lib/api/custom-page.types';
import { IGroupedMeetingsList } from '../GridLayout/index';
import { CarouselWrapperByDay } from './CarouselWrapper';
import { EventType } from '../LayoutWrapper';
import './Card.scss';

export interface ICarouselSection {
  event: EventType;
  selectedDate: string;
  showAllDates: boolean;
  showPastMeetings: boolean;
  meetings: IGroupedMeetingsList;
  searchedValue: string;
  setFilteredMeetingsCount: (length: number) => void;
  eventToken: string;
}

const CarouselViewByDay = (props: ICarouselSection): JSX.Element => {
  const { event, meetings, showAllDates, showPastMeetings, searchedValue, setFilteredMeetingsCount, eventToken } = props;
  const isCarouselViewByDay = event?.layout === SessionLayout.CAROUSEL_VIEW_BY_DAY;
  const filteredMeetingsCount = useRef(0);
  const handleSetFilteredMeetingsCount = (count: number): void => {
    filteredMeetingsCount.current = filteredMeetingsCount.current + count;
    setFilteredMeetingsCount(filteredMeetingsCount.current);
  };

  return (
    <>
      {
        Object.keys(meetings).map((date: string, index: number) => (
          <CarouselWrapperByDay
            key={date}
            event={event}
            selectedDate={date}
            meetings={meetings[date]}
            showAllDates={showAllDates}
            isCarouselViewByDay={isCarouselViewByDay}
            showPastMeetings={showPastMeetings}
            searchedValue={searchedValue}
            setFilteredMeetingsCount={count => handleSetFilteredMeetingsCount(count)}
            eventToken={eventToken}
          />
        ))
      }
    </>
  );
};

export { CarouselViewByDay };
